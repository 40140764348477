body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Loader */
.lds-dual-ring {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 6px;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
button:hover .lds-dual-ring:after {
  border-color: #fff transparent #fff transparent;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url(./assets/fonts/PlusJakartaSans-VariableFont_wght.ttf);
}

@font-face {
  font-family: 'Bebas Neue';
  src: url(./assets/fonts/BebasNeue-Regular.ttf);
}

@font-face {
  font-family: 'Britney';
  src: url(./assets/fonts/Britney-Variable.ttf);
}

@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-VariableFont_wght.ttf);
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }

  .HOVER-GLOW:hover {
    @apply shadow-3xl shadow-[#8D7BFF]/50
  }
}